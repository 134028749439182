import React from 'react'
import { get, map } from 'lodash'
import routes from '~/routes'
import Markdown from 'components/common/Markdown'
import NavigationHelper from 'components/block/NavigationHelper'
import { VisibilityGroupBreadcrumb } from 'components/block/Breadcrumb'
import Feedback from 'components/block/Feedback'
import SearchBar from 'components/block/SearchBar'
import ContentItems from 'components/block/ContentItem/ContentItems'
import style from './visibilitygroup.module.scss'

export default ({ data }) => {
  return (
    <div className={ style.main }>
      <NavigationHelper title={ data.title }>
        <VisibilityGroupBreadcrumb visibilityGroup={ data } />
      </NavigationHelper>
      <div className={ style.container }>
        <SearchBar />
      </div>
      <ContentItems
        className={ style.items }
        items={ map(data.category, category => ({
          title: category.title,
          url: routes.category(category),
          image: get(category, 'image.fluid.src'),
        }))
      } />
    </div>
  )
}