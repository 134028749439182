import React from 'react'
import { t } from '@lingui/macro'
import { get } from 'lodash'
import { withLocale } from 'components/common/Locale'
import { Default as Layout } from 'components/layout/Layout'
import VisibilityGroup from 'components/section/VisibilityGroup'

export default ({ pageContext = {}, location }) => (
  <Layout location={ location }>
    <VisibilityGroup data={ get(pageContext, 'data.visibilityGroup') } />
  </Layout>
)